<template>
  <div class="h-full">
    <!-- 1st Row -->
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <div
        class="d-flex align-center cursor-pointer mr-4"
        @click="$emit('close-campaign-inbound-view')"
      >
        <!-- Back Button -->
        <v-btn
          icon
          small
          class="me-2"
        >
          <v-icon size="28">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>

        <!-- Subject -->
        <h1 class="font-weight-medium text-base me-2 text-truncate">
          {{ campaignInboundView.campaign.subject }}
        </h1>
      </div>

      <!-- Labels -->
      <v-badge
        v-for="label in campaignInboundView.labels"
        :key="label"
        :color="resolveCampaignInboundLabelColor(label)"
        inline
        :content="label"
        class="email-label-chip text-capitalize v-badge-light-bg"
        :class="`${resolveCampaignInboundLabelColor(label)}--text`"
      >
      </v-badge>
      <v-spacer></v-spacer>

      <!-- Navigation Prev Vs Next -->
      <v-btn
        icon
        small
        :disabled="!openCampaignInboundMeta.hasPrev"
        @click="$emit('change-open-campaign-inbound', 'prev')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        :disabled="!openCampaignInboundMeta.hasNext"
        @click="$emit('change-open-campaign-inbound', 'next')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>

    <v-divider></v-divider>
    <!-- 2nd Row: Actions -->
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <!-- Actions: Send trash (Only !== trash) -->
      <!-- <v-btn
        v-show="$route.params.folder !== 'trash'"
        icon
        small
        class="me-2"
        @click="$emit('move-campaign-inbound-to-folder', 'trash')"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn> -->

      <!-- Actions: Set Unread (Set read is not posible becose is readed yet) -->
      <v-btn
        icon
        small
        class="me-2"
        @click="$emit('mark-campaign-inbound-unread')"
      >
        <v-icon size="22">
          {{ icons.mdiEmailOutline }}
        </v-icon>
      </v-btn>

      <!-- Actions: Set labels to ['Personal', 'Company', ...] -->
      <MenuLabels
        :is-visible="true"
        :update-label-menu-list-items="updateLabelMenuListItems"
        @on-label-click="(value) => $emit('update-campaign-inbound-label', value)"
      ></MenuLabels>

      <v-spacer></v-spacer>

      <!-- Other actions: Accepted | Rejected -->
      <div class="d-flex align-center">
        <!-- :color="resolveCampaignInboundStatus(campaignInboundView.status).color" -->
        <v-badge
          inline
          class="email-label-chip text-capitalize v-badge-light-bg mr-4"
          :class="`${resolveCampaignInboundStatus(campaignInboundView.status).color}--text`"
          :content="campaignInboundView.status"
        ></v-badge>

        <!-- Custom Actions: Accepted | Rejected -->
        <!-- Received: La puedo Aceptar (Accepted) -->
        <v-btn
          v-if="campaignInboundView.status === CAMPAIGN_INBOUND_STATUS.RECEIVED"
          :icon="false"
          outlined
          small
          class="me-2"
          :color="customActionAccepted.color"
          @click="$emit('set-campaign-inbound-action', customActionAccepted.value)"
        >
          <v-icon size="22">
            {{ customActionAccepted.icon }}
          </v-icon>
          <span class="pl-2">{{ customActionAccepted.title }}</span>
        </v-btn>

        <!-- Received: La puedo Rechazar (Rejected) -->
        <v-btn
          v-if="campaignInboundView.status === CAMPAIGN_INBOUND_STATUS.RECEIVED"
          icon
          outlined
          small
          class="me-2"
          :color="customActionRejected.color"
          @click="$emit('set-campaign-inbound-action', customActionRejected.value)"
        >
          <v-icon size="22">
            {{ customActionRejected.icon }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>

    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-campaign-inbound-view-content"
    >
      <!-- CampaignInbound Messages -->
      <div
        class="position-relative ma-sm-7 ma-4"
      >
        <!-- <campaign-inbound-message-card
          :message="campaignInboundView"
          style="top:0;z-index:10"
        /> -->

        <!-- Cards: Supplier and ProductSupplier -->
        <v-card
          outlined
          class="mt-4"
          style="top:0;z-index:10"
        >
          <!--  -->
          <v-card-text class="d-flex flex-wrap align-center pa-4">
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="campaignInboundView.isStarred ? 'warning': null"
              @click.stop="$emit('toggle-campaign-inbound-starred', campaignInboundView)"
            >
              <v-icon>
                {{ campaignInboundView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Supplier(Who contact me) ProductSupplier(His produrct) -->
            <SupplierCard :supplier="campaignInboundView.campaign.productSupplier.supplier" />
            <ProductSupplierCard :product-supplier="campaignInboundView.campaign.productSupplier" />

            <!-- Right -->
            <v-spacer></v-spacer>
            <span
              v-if="$vuetify.breakpoint.smAndUp"
              class="text--disabled text-xs me-2 text-no-wrap"
            >
              {{ formatDate(campaignInboundView.createdAt, { dateStyle: 'long', timeStyle: 'short' }) }}
            </span>
            <div
              v-else
              class="d-flex flex-column align-center"
            >
              <v-btn
                icon
                small
                :color="campaignInboundView.isStarred ? 'warning': null"
                @click.stop="$emit('toggle-campaign-inbound-starred', campaignInboundView)"
              >
                <v-icon>
                  {{ campaignInboundView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
                </v-icon>
              </v-btn>
              <span class="text--disabled text-xs">{{ formatDateToMonthShort(campaignInboundView.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <!-- Message -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="ma-5"
            v-html="campaignInboundView.campaign.message"
          ></div>
        </v-card>

        <!-- Content -->
        <v-row class="mt-4">
          <v-col
            cols="12"
            lg="12"
            md="12"
          >
            <CampaignInboundSummary :campaign-inbound="campaignInboundView"></CampaignInboundSummary>
          </v-col>
        </v-row>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiTrashCanOutline,
  mdiStarOutline,
  mdiStar,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import useCampaignInboundHandler from './useCampaignInboundHandler'

// Local Components
// import CampaignInboundContent from './CampaignInboundContent.vue'
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import SupplierCard from '@/components/Cards/SupplierCard.vue'
import CampaignInboundSummary from './CampaignInboundSummary.vue'
import MenuLabels from '@/components/MenuLabels.vue'

export default {
  components: {
    PerfectScrollbar,

    // Local Components
    ProductSupplierCard,
    SupplierCard,
    CampaignInboundSummary,
    MenuLabels,
  },
  props: {
    campaignInboundView: {
      type: Object,
      required: true,
    },
    openCampaignInboundMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // ————————————————————————————————————
    //* ——— useCampaignInbound
    // ————————————————————————————————————
    const {
      resolveCampaignInboundLabelColor,
      resolveCampaignInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CAMPAIGN_INBOUND_STATUS,
    } = useCampaignInboundHandler()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // useCampaignInbound
      resolveCampaignInboundLabelColor,
      resolveCampaignInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CAMPAIGN_INBOUND_STATUS,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiTrashCanOutline,
        mdiStarOutline,
        mdiStar,
      },
      formatDate,
      formatDateToMonthShort,
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/preset/preset/mixins.scss';
  // @import '~vuetify/src/components/VCard/_variables.scss';

  @include theme--child(ps-campaign-inbound-view-content) using ($material) {
    background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
  }

  .ps-campaign-inbound-view-content {
    height: calc(100% - 46px - 46px - 2px);

    // .view-all-toggler {
    //   &:hover {
    //     color: var(--v-primary-base) !important;
    //   }
    // }
  }
</style>
