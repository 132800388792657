import axios from '@axios'

// interface CampaignInterface {
//     id: string;
//     name: string;
// }

// CampaignInbound Router list
// GET    /marketing/campaign/inbounds             Retrieves the collection of CampaignInbound resources.
// GET    /marketing/campaign/inbounds/{id}        Retrieves a CampaignInbound resource.
// DELETE /marketing/campaign/inbounds/{id}        Removes a CampaignInbound resource.
//
// Other Actions
// POST   /marketing/campaign/inbounds/status/{id}  Change status  of a CampaignInbound resource.
// POST   /marketing/campaign/inbounds/starred/{id} Change starred of a CampaignInbound resource.
// POST   /marketing/campaign/inbounds/readed/{id}  Change readed  of a CampaignInbound resource.
// POST   /marketing/campaign/inbounds/labels/{id}  Change label   of a CampaignInbound resource.
const routeMarketingCampaignInbounds = '/marketing/campaign/inbounds'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class CampaignInbound extends BaseApiService { constructor() { super(axios) }
class CampaignInbound {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchCampaignInbounds(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingCampaignInbounds}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Optional click count when read
  fetchCampaignInbound(id, clickCount = false) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingCampaignInbounds}/${id}`, { params: { clickCount } })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeCampaignInbound(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeMarketingCampaignInbounds}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  // { campaignInboundId, status: [accepted | rejected] }
  changeCampaignInboundStatus(campaignInboundId, newStatus) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingCampaignInbounds}/status/${campaignInboundId}`, { status: newStatus })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { campaignInboundId, isStarred: [true | false] }
  changeCampaignInboundStarred(campaignInboundId, newIsStarred) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingCampaignInbounds}/starred/${campaignInboundId}`, {
          isStarred: newIsStarred,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { campaignInboundId, isRead: [true | false] }
  changeCampaignInboundReaded(campaignInboundId, newIsRead) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingCampaignInbounds}/readed/${campaignInboundId}`, { isRead: newIsRead })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { campaignInboundId, label: ["important" | "company" ...] }
  changeCampaignInboundLabels(campaignInboundId, toggleLabel) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .post('/marketing/campaign/inbound/update-label', payload)
        .post(`${this.API_BASE}${routeMarketingCampaignInbounds}/labels/${campaignInboundId}`, { label: toggleLabel })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useCampaignInbound (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useCampaignInbound = new CampaignInbound()
export default useCampaignInbound
