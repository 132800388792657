<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false)"
  >
    <!-- Esta plantilla es para activar el dialog -->
    <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

    <v-card>
      <!-- Toolbar -->
      <v-toolbar
        dark
      >
        <v-btn
          icon
          dark
          @click="$emit('update:is-dialog-active',false);"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title>Accept Contact</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-2 pa-md-5">
        <CampaignInboundSummary :campaign-inbound="campaignInboundLocal"></CampaignInboundSummary>
      </v-card-text>

      <!-- Foooter: Cancel + Published -->
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="$emit('update:is-dialog-active',false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
          <span class="ml-1">{{ buttonTextCancel }}</span>
        </v-btn>

        <v-btn
          :icon="false"
          outlined
          small
          class="me-2"
          :color="customActionAccepted.color"
          @click="$emit('update:is-dialog-active',false); $emit('ok', campaignInboundLocal)"
        >
          <v-icon size="22">
            {{ customActionAccepted.icon }}
          </v-icon>
          <span class="pl-2">{{ customActionAccepted.title }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiEmailSendOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// Services
import useCampaignInboundHandler from './useCampaignInboundHandler'

// Components
import CampaignInboundSummary from './CampaignInboundSummary.vue'

export default {
  components: {
    CampaignInboundSummary,
  },
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    campaignInbound: {
      type: Object,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: 'Publish',
    },
    buttonTextCancel: {
      type: String,
      default: 'Cancel',
    },
  },
  setup(props, { emit }) {
    const { customActionAccepted } = useCampaignInboundHandler()

    const campaignInboundLocal = ref(props.campaign)
    watch(() => props.campaignInbound, () => {
      campaignInboundLocal.value = props.campaignInbound
    })

    const onCampaignInboundPublishedConfirmed = () => {
      emit('connect', props.id)
    }

    return {
      campaignInboundLocal,
      onCampaignInboundPublishedConfirmed,

      // UI
      customActionAccepted,
      icons: {
        mdiClose,
        mdiEmailSendOutline,
      },
    }
  },
}
</script>
