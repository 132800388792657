<template>
  <v-card
    class="rounded-0"
    outlined
  >
    <v-card-title>
      {{ campaignInbound.campaign.name }}
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                KEY
              </th>
              <th>
                VALUE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, key, index) in campaignInbound"
              :key="index"
            >
              <td class="text-no-wrap">
                {{ key }}
              </td>
              <td class="text-no-wrap">
                {{ renderObject(key, data) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    campaignInbound: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    /*
      CampaignInbound
        supplier: Object
        productSupplier: Object
     */
    const renderObject = (key, data) => {
      if (data instanceof Object) {
        if (key === 'supplier') {
          return data.user.firstName
        }

        if (key === 'productSupplier') {
          return data.name
        }

        return data.id
      }

      return data
    }

    return {
      renderObject,
    }
  },
}
</script>
