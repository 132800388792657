<template>
  <v-card class="rounded-0 app-campaign-inbound h-full position-relative overflow-hidden text-sm">
    <!-- Dialog confirm Accept contact -->
    <CampaignInboundAcceptDialog
      v-model="isCampaignInboundAcceptDialogVisible"
      :campaign-inbound="campaignInboundAcceptDialog"
      @ok="(campaignInbound) => askCampaignInboundAcceptDialogResponse(campaignInbound)"
    ></CampaignInboundAcceptDialog>

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="260"
      mobile-breakpoint="sm"
      absolute
      :right="$vuetify.rtl"
      touchless
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <campaign-inbound-left-sidebar-content
        :campaign-inbounds-meta="campaignInboundsMeta"
      ></campaign-inbound-left-sidebar-content>
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative"
      :style="contentStyles"
    >
      <!-- 1st Row -->
      <div class="d-flex align-center px-4">
        <v-btn
          v-show="!isLeftSidebarOpen"
          :icon="false"
          outlined
          small
          color="primary"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="22">
            {{ icons.mdiTune }}
          </v-icon>
          <span class="pl-2">{{ $route.params.folder || $route.params.label }}</span>
        </v-btn>
        <v-text-field
          v-model="searchQuery"
          placeholder="search..."
          outlined
          hide-details
          class="campaign-inbound-search-input"
          :prepend-inner-icon="icons.mdiMagnify"
        ></v-text-field>
      </div>

      <v-divider></v-divider>

      <!-- 2nd Row: Action Multiple -->
      <div class="d-flex align-center px-4 py-2">
        <v-checkbox
          class="d-flex mt-0 pt-0"
          hide-details
          :input-value="selectAllCampaignInboundCheckbox"
          :indeterminate="isSelectAllCampaignInboundCheckboxIndeterminate"
          :disabled="!campaignInbounds.length"
          @change="selectAllCheckboxUpdate"
        ></v-checkbox>

        <!-- Multiple: Basic Actions: Read / Unread -->
        <v-btn
          v-show="selectedCampaignInbounds.length"
          icon
          small
          class="me-3"
          @click="isAllSelectedCampaignInboundAreRead ? markSelectedCampaignInboundAsUnreadMultiple() : markSelectedCampaignInboundAsReadMultiple()"
        >
          <v-icon size="22">
            {{ isAllSelectedCampaignInboundAreRead ? icons.mdiEmailOutline : icons.mdiEmailOpenOutline }}
          </v-icon>
        </v-btn>

        <!-- Multiple: Basic Actions : Labels -->
        <MenuLabels
          :is-visible="(selectedCampaignInbounds.length > 0)"
          :update-label-menu-list-items="updateLabelMenuListItems"
          @on-label-click="(value) => updateSelectedCampaignInboundLabelMultiple(value)"
        ></MenuLabels>

        <!-- Multiple: Custom Actions -->
        <div
          v-if="selectedCampaignInbounds.length"
          class="ml-2"
        >
          <!-- Received: Las puedo rechazar (rejected) -->
          <v-btn
            v-if="$route.params.folder === CAMPAIGN_INBOUND_STATUS.RECEIVED"
            icon
            small
            class="me-2"
            :color="customActionRejected.color"
            @click.stop="setSelectedCampaignInboundActionMultiple(customActionRejected.value)"
          >
            <v-icon size="22">
              {{ customActionRejected.icon }}
            </v-icon>
          </v-btn>

          <!-- Received: Las puedo aceptar (accepted) -->
          <v-btn
            icon
            small
            class="me-2"
            :color="customActionAccepted.color"
            @click.stop="setSelectedCampaignInboundActionMultiple(customActionAccepted.value)"
          >
            <v-icon size="22">
              {{ customActionAccepted.icon }}
            </v-icon>
          </v-btn>
        </div>

        <!-- Right Side -->
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          class="me-2"
          @click="fetchCampaignInbounds"
        >
          <v-icon size="21">
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
      </div>

      <!-- Loading indicator -->
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        class="position-absolute"
      ></v-progress-linear>
      <v-divider></v-divider>

      <!-- CampaignInbound List -->
      <perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-campaign-inbounds-list"
      >
        <v-hover
          v-for="campaignInbound in campaignInbounds"
          #default="{ hover: isHovered }"
          :key="campaignInbound.id"
        >
          <div
            :class="[{'hovered elevation-3': isHovered}, { 'campaign-inbound-read': campaignInbound.isRead }]"
            class="campaign-inbound-item pa-4 d-flex align-center text-no-wrap cursor-pointer"
            @click="updatecampaignInboundView(campaignInbound)"
          >
            <v-checkbox
              class="d-flex mt-0 pt-0"
              hide-details
              :input-value="selectedCampaignInbounds.includes(campaignInbound.id)"
              @click.stop
              @change="toggleSelectedCampaignInbound(campaignInbound.id)"
            ></v-checkbox>
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="campaignInbound.isStarred ? 'warning': null"
              @click.stop="toggleStarred(campaignInbound)"
            >
              <v-icon>
                {{ campaignInbound.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Cards: Supplier(Emisor campaña) and ProductSupplier(Producto del emiso) -->
            <SupplierCard :supplier="campaignInbound.campaign.productSupplier.supplier" />
            <ProductSupplierCard :product-supplier="campaignInbound.campaign.productSupplier" />

            <!-- Subject + Labels -->
            <div class="d-flex flex-column text-truncate">
              <span
                class="text-truncate me-4 ms-sm-2 ms-0"
                :class="campaignInbound.isRead ? '' : 'font-weight-bold'"
                :style="campaignInbound.isRead ? '' : 'color: black'"
              >
                {{ campaignInbound.campaign.subject }}
              </span>
              <div class="d-flex flex-row text-truncate">
                <!-- <template v-if="$vuetify.breakpoint.smAndUp"> -->
                <template>
                  <!-- labels  > 1: Indicamos solo los puntos (Sin Opacidad) -->
                  <!-- labels == 1: Indicamos el texto (Con Opacidad) -->
                  <v-badge
                    v-for="label in campaignInbound.labels"
                    :key="label"
                    :color="resolveCampaignInboundLabelColor(label)"
                    :dot="campaignInbound.labels.length > 1"
                    inline
                    class="align-self-center label-dot"
                    :class="!(campaignInbound.labels.length > 1) ? `${resolveCampaignInboundLabelColor(label)}--text v-badge-light-bg` : ''"
                    :content="label"
                  ></v-badge>
                </template>
              </div>
            </div>

            <v-spacer></v-spacer>

            <!-- Time and Actions On Hover -->
            <div v-show="isHovered">
              <!-- Action Read / Unread -->
              <v-btn
                icon
                small
                class="me-2"
                @click.stop="campaignInbound.isRead ? markSelectedCampaignInboundAsUnreadMultiple([campaignInbound.id]) : markSelectedCampaignInboundAsReadMultiple([campaignInbound.id])"
              >
                <v-icon size="22">
                  {{ campaignInbound.isRead ? icons.mdiEmailOutline : icons.mdiEmailOpenOutline }}
                </v-icon>
              </v-btn>

              <!-- Custom Actions: Accepted | Rejected -->

              <!-- Received: La puedo aceptar (Accepted) -->
              <!-- @click.stop="setSelectedCampaignInboundActionMultiple(customActionAccepted.value, [campaignInbound.id])" -->
              <v-btn
                v-if="campaignInbound.status === CAMPAIGN_INBOUND_STATUS.RECEIVED"
                icon
                small
                outlined
                class="me-2"
                :color="customActionAccepted.color"
                @click.stop="askCampaignInboundAcceptDialog(campaignInbound)"
              >
                <v-icon size="22">
                  {{ customActionAccepted.icon }}
                </v-icon>
              </v-btn>

              <!-- Received: La puedo rechazar (Rejected) -->
              <v-btn
                v-if="campaignInbound.status === CAMPAIGN_INBOUND_STATUS.RECEIVED"
                icon
                small
                outlined
                class="me-2"
                :color="customActionRejected.color"
                @click.stop="setSelectedCampaignInboundActionMultiple(customActionRejected.value, [campaignInbound.id])"
              >
                <v-icon size="22">
                  {{ customActionRejected.icon }}
                </v-icon>
              </v-btn>

              <!-- Action: Send to trash (Only when no trash) -->
              <!-- Action: Delete permanent (Only when trash) -->
              <!-- <v-btn
                v-show="$route.params.folder === 'trash'"
                icon
                small
                class="me-2"
                @click.stop="deleteSelectedCampaignInboundMultiple([campaignInbound.id])"
              >
                <v-icon size="22">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn> -->
            </div>

            <!-- Not hovered: Estatus + Time -->
            <div v-show="!isHovered">
              <div class="d-flex flex-column align-center">
                <span class="text-xs text--disabled">{{ formatDateToMonthShort(campaignInbound.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
                <v-badge
                  inline
                  class="email-label-chip text-capitalize v-badge-light-bg"
                  :class="`${resolveCampaignInboundStatus(campaignInbound.status).color}--text`"
                  :content="campaignInbound.status"
                ></v-badge>
              </div>
            </div>
          </div>
        </v-hover>
        <div
          class="d-none text-center text--primary font-weight-medium"
          :class="{'d-block': !campaignInbounds.length}"
        >
          <p class="my-4">
            No Items Found
          </p>
        </div>
      </perfect-scrollbar>

      <!-- CampaignInbound Details -->
      <v-navigation-drawer
        v-model="isCampaignInboundViewOpen"
        :right="!$vuetify.rtl"
        absolute
        mobile-breakpoint="sm"
        width="100%"
      >
        <!-- @move-campaign-inbound-to-folder="moveOpenCampaignInboundToFolder" -->
        <campaign-inbound-view
          v-if="campaignInboundView.campaign"
          :campaign-inbound-view="campaignInboundView"
          :open-campaign-inbound-meta="openCampaignInboundMeta"
          @change-open-campaign-inbound="changeOpenCampaignInbound"
          @close-campaign-inbound-view="isCampaignInboundViewOpen = false"
          @mark-campaign-inbound-unread="markOpenCampaignInboundAsUnread"
          @update-campaign-inbound-label="updateOpenCampaignInboundLabel"

          @toggle-campaign-inbound-starred="(campaignInbound) => toggleStarred(campaignInbound)"
          @set-campaign-inbound-action="(status) => setOpenCampaignInboundAction(status)"
        ></campaign-inbound-view>
      </v-navigation-drawer>
    </div>
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { useRouter } from '@core/utils'
import {
  mdiTune,
  mdiMagnify,
  mdiTrashCanOutline,
  mdiReload,
  mdiStar,
  mdiStarOutline,
  mdiEmailOpenOutline,
  mdiEmailOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// Store
import { formatDateToMonthShort } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Services
import useCampaignInbound from '@/services/api/modules/campaignInbound'
import useCampaignInboundHandler from './useCampaignInboundHandler'

// Local Componnets
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import SupplierCard from '@/components/Cards/SupplierCard.vue'
import CampaignInboundLeftSidebarContent from './CampaignInboundLeftSidebarContent.vue'
import CampaignInboundView from './CampaignInboundView.vue'
import CampaignInboundAcceptDialog from './CampaignInboundAcceptDialog.vue'
import MenuLabels from '@/components/MenuLabels.vue'

export default {
  components: {
    CampaignInboundLeftSidebarContent,
    CampaignInboundView,
    ProductSupplierCard,
    SupplierCard,
    CampaignInboundAcceptDialog,
    MenuLabels,

    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    // Register module
    // const MARKETING_INBOUND_APP_STORE_MODULE_NAME = 'marketing-inbound'
    // if (!store.hasModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME)) store.registerModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME, campaignInboundStoreModule)
    // onUnmounted(() => {
    //   if (store.hasModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME)) store.unregisterModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME)
    // })

    // LeftSidebar management (Integrated with Main Component)
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 260 })

    // ————————————————————————————————————
    //* ——— Router
    // ————————————————————————————————————
    const { route, router } = useRouter()

    // Search Query & Route Params
    const searchQuery = computed({
      get: () => route.value.query.q,
      set: val => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      },
    })
    const routeParams = computed(() => route.value.params)

    // Fetch campaignInbound again of search query change or route param change
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, routeParams], () => fetchCampaignInbounds())

    // ————————————————————————————————————
    //* ——— useCampaignInbound
    // ————————————————————————————————————
    // sendCampaignInboundNotification,
    const {
      resolveCampaignInboundLabelColor,
      resolveCampaignInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CAMPAIGN_INBOUND_STATUS,
    } = useCampaignInboundHandler()

    //
    // ————————————————————————————————————
    //* ——— CampaignInbounds & CampaignInbounds Meta (LeftSide)
    // ————————————————————————————————————
    const campaignInbounds = ref([])
    const campaignInboundsMeta = ref({})
    const loading = ref(false)

    const fetchCampaignInbounds = () => {
      loading.value = true

      //   store.dispatch('marketing/fetchCampaignInbounds', {
      // folder meaning
      //  received filter by status (Default search)
      //  finished filter by status
      //  rejected filter by status
      //  starred  filter by isStarred
      //  trash    filter by isDeleted or status deleted
      useCampaignInbound
        .fetchCampaignInbounds({
          q: searchQuery.value,

          // folder: router.currentRoute.params.folder || 'received',
          folder:
            router.currentRoute.params.folder
            || (router.currentRoute.params.label ? undefined : CAMPAIGN_INBOUND_STATUS.RECEIVED),
          label: router.currentRoute.params.label,
        })
        .then(response => {
          const { campaignInbounds: campaignInboundsResponse, campaignInboundsMeta: campaignInboundsMetaResponse } = response.data
          if (campaignInboundsResponse) {
            campaignInbounds.value = campaignInboundsResponse
            campaignInboundsMeta.value = campaignInboundsMetaResponse
          } else {
            campaignInbounds.value = []
            campaignInboundsMeta.value = {}
          }

          // Store
          // const totalPending = campaignInboundsMeta.value['CAMPAIGN_INBOUND_STATUS.RECEIVED'] || 0
          // const totalPending = campaignInboundsMeta.value?.received || 0
          const { received } = campaignInboundsMeta.value
          store.commit('user/UPDATE_CAMPAIGN_INBOUNDS_PENDING_COUNT', received || 0)
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchCampaignInbounds()

    // ————————————————————————————————————
    //* ——— CampaignInbounds Selection
    // ————————————————————————————————————
    const selectedCampaignInbounds = ref([])
    const toggleSelectedCampaignInbound = campaignInboundId => {
      const campaignInboundIndex = selectedCampaignInbounds.value.indexOf(campaignInboundId)

      if (campaignInboundIndex === -1) selectedCampaignInbounds.value.push(campaignInboundId)
      else selectedCampaignInbounds.value.splice(campaignInboundIndex, 1)
    }
    const selectAllCampaignInboundCheckbox = computed(
      () => campaignInbounds.value.length && campaignInbounds.value.length === selectedCampaignInbounds.value.length,
    )
    const isSelectAllCampaignInboundCheckboxIndeterminate = computed(
      () => Boolean(selectedCampaignInbounds.value.length)
        && campaignInbounds.value.length !== selectedCampaignInbounds.value.length,
    )
    const selectAllCheckboxUpdate = val => {
      selectedCampaignInbounds.value = val ? campaignInbounds.value.map(campaignInbound => campaignInbound.id) : []
    }
    const isAllSelectedCampaignInboundAreRead = computed(() => {
      // Get array of isRead property value from selected campaignInbounds array
      const campaignInboundsIsRead = selectedCampaignInbounds.value.map(
        campaignInboundId => campaignInbounds.value.find(campaignInbound => campaignInbound.id === campaignInboundId).isRead,
      )

      // Return icon based on selection (every comprueba si todos los elementos cumplen una condición)
      return campaignInboundsIsRead.every(campaignInboundIsRead => campaignInboundIsRead)
    })

    // ? Watcher to reset selectedCampaignInbounds is somewhere below due to watch dependecy fullfilment

    // ————————————————————————————————————
    //* ——— CampaignInbound Actions
    // ————————————————————————————————————
    const toggleStarred = campaignInbound => {
      useCampaignInbound.changeCampaignInboundStarred(campaignInbound.id, !campaignInbound.isStarred).then(() => {
        // eslint-disable-next-line no-param-reassign
        campaignInbound.isStarred = !campaignInbound.isStarred
      })
    }

    const updateSelectedCampaignInboundLabelMultiple = (
      label,
      campaignInboundIds = selectedCampaignInbounds.value,
    ) => {
      const tasks = campaignInboundIds.map(campaignInboundId => useCampaignInbound.changeCampaignInboundLabels(campaignInboundId, label))
      Promise.all(tasks).finally(() => {
        fetchCampaignInbounds()
        selectedCampaignInbounds.value = []
      })
    }

    const markSelectedCampaignInboundAsUnreadMultiple = (
      campaignInboundIds = selectedCampaignInbounds.value,
    ) => {
      const tasks = campaignInboundIds.map(campaignInboundId => useCampaignInbound.changeCampaignInboundReaded(campaignInboundId, false))
      Promise.all(tasks).finally(() => {
        selectedCampaignInbounds.value = []
        fetchCampaignInbounds()
      })
    }

    const markSelectedCampaignInboundAsReadMultiple = (
      campaignInboundIds = selectedCampaignInbounds.value,
    ) => {
      const tasks = campaignInboundIds.map(campaignInboundId => useCampaignInbound.changeCampaignInboundReaded(campaignInboundId, true))
      Promise.all(tasks).finally(() => {
        selectedCampaignInbounds.value = []
        fetchCampaignInbounds()
      })
    }

    // Pendiente (Igual no se permite)
    const deleteSelectedCampaignInboundMultiple = (
      campaignInboundIds = selectedCampaignInbounds.value,
    ) => {
      const tasks = campaignInboundIds.map(campaignInboundId => useCampaignInbound.removeCampaignInbound(campaignInboundId))
      Promise.all(tasks).finally(() => {
        fetchCampaignInbounds()
        selectedCampaignInbounds.value = []
      })
    }

    // Custom Actions: [ accepted | rejected ]
    // const setSelectedCampaignInboundAction = (
    //   action,
    //   campaignInboundId,
    // ) => {
    //   // if (action === CAMPAIGN_INBOUND_STATUS.ACCEPTED || action === CAMPAIGN_INBOUND_STATUS.REJECTED) {
    //   useCampaignInbound
    //     .changeCampaignInboundStatus(campaignInboundId, action)
    //     .then(() => {
    //       sendCampaignInboundNotification(`ContactRequest Inbound ${action}`)
    //     })
    //     .finally(() => {
    //       selectedCampaignInbounds.value = []
    //       fetchCampaignInbounds()
    //     })
    // }
    const setSelectedCampaignInboundActionMultiple = (
      status,
      campaignInboundIds = selectedCampaignInbounds.value,
    ) => {
      const tasks = campaignInboundIds.map(campaignInboundId => useCampaignInbound.changeCampaignInboundStatus(campaignInboundId, status))
      Promise.all(tasks).finally(() => {
        fetchCampaignInbounds()
        selectedCampaignInbounds.value = []
      })
    }

    // ————————————————————————————————————
    //* ——— CampaignInbound View
    // ————————————————————————————————————
    const isCampaignInboundViewOpen = ref(false)
    const campaignInboundView = ref({})

    // >>> Open Meta (Prev < > Next)
    const openCampaignInboundMeta = computed(() => {
      const openCampaignInboundIndex = campaignInbounds.value.findIndex(e => e.id === campaignInboundView.value.id)

      return {
        hasNext: Boolean(campaignInbounds.value[openCampaignInboundIndex + 1]),
        hasPrev: Boolean(campaignInbounds.value[openCampaignInboundIndex - 1]),
      }
    })
    const changeOpenCampaignInbound = dir => {
      const openCampaignInboundIndex = campaignInbounds.value.findIndex(e => e.id === campaignInboundView.value.id)
      const newCampaignInboundIndex = dir === 'prev' ? openCampaignInboundIndex - 1 : openCampaignInboundIndex + 1

      // TODO - Set as read like when clic individual
      // campaignInboundView.value = campaignInbounds.value[newCampaignInboundIndex]
      // eslint-disable-next-line no-use-before-define
      updatecampaignInboundView(campaignInbounds.value[newCampaignInboundIndex])
    }

    // >>> View Actions
    const updatecampaignInboundView = campaignInbound => {
      // Mark campaignInbound is read
      if (!campaignInbound.isRead) {
        useCampaignInbound
          .changeCampaignInboundReaded(campaignInbound.id, true)
          .then(() => {
            // If open campaignInbound is unread then decrease badge count for campaignInbound meta based on campaignInbound folder
            if (!campaignInbound.isRead && campaignInbound.status === CAMPAIGN_INBOUND_STATUS.RECEIVED) {
              campaignInboundsMeta.value[campaignInbound.status] -= 1
            }
            // eslint-disable-next-line no-param-reassign
            campaignInbound.isRead = true

            // Store
            store.commit(
              'user/UPDATE_CAMPAIGN_INBOUNDS_PENDING_COUNT',
              store.getters['user/campaignInboundsPendingCount'] - 1,
            )
          })
          .finally(() => {
            campaignInboundView.value = campaignInbound
            isCampaignInboundViewOpen.value = true
          })
      } else {
        campaignInboundView.value = campaignInbound
        isCampaignInboundViewOpen.value = true
      }
    }

    const updateOpenCampaignInboundLabel = label => {
      selectedCampaignInbounds.value = [campaignInboundView.value.id]
      updateSelectedCampaignInboundLabelMultiple(label)

      // Update label in open campaignInbound
      const labelIndex = campaignInboundView.value.labels.indexOf(label)
      if (labelIndex === -1) campaignInboundView.value.labels.push(label)
      else campaignInboundView.value.labels.splice(labelIndex, 1)

      selectedCampaignInbounds.value = []
    }

    const markOpenCampaignInboundAsUnread = () => {
      selectedCampaignInbounds.value = [campaignInboundView.value.id]
      markSelectedCampaignInboundAsUnreadMultiple()

      selectedCampaignInbounds.value = []
      isCampaignInboundViewOpen.value = false
    }

    // Actions: Accept, Reject
    const setOpenCampaignInboundAction = status => {
      selectedCampaignInbounds.value = [campaignInboundView.value.id]

      if (status === CAMPAIGN_INBOUND_STATUS.ACCEPTED) {
        // eslint-disable-next-line no-use-before-define
        askCampaignInboundAcceptDialog(campaignInboundView.value)
      } else {
        setSelectedCampaignInboundActionMultiple(status)

        selectedCampaignInbounds.value = []
        isCampaignInboundViewOpen.value = false
      }
    }

    // * If someone clicks on filter while viewing detail => Close the campaignInbound detail view
    watch(routeParams, () => {
      isCampaignInboundViewOpen.value = false
    })

    // * Watcher to reset selectedCampaignInbounds
    // ? You can also use isCampaignInboundViewOpen (instead of `campaignInboundView`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([campaignInboundView, routeParams], () => {
      selectedCampaignInbounds.value = []
    })

    // Accept CampaignInbound with Dialog
    const isCampaignInboundAcceptDialogVisible = ref(false)
    const campaignInboundAcceptDialog = ref(null)
    const askCampaignInboundAcceptDialog = campaignInbound => {
      isCampaignInboundAcceptDialogVisible.value = true
      campaignInboundAcceptDialog.value = campaignInbound
    }
    const askCampaignInboundAcceptDialogResponse = campaignInbound => {
      setSelectedCampaignInboundActionMultiple(CAMPAIGN_INBOUND_STATUS.ACCEPTED, [campaignInbound.id])
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isLeftSidebarOpen,
      contentStyles,

      // Search Query
      searchQuery,

      // CampaignInbounds & CampaignInbound Meta
      campaignInbounds,
      campaignInboundsMeta,
      fetchCampaignInbounds,
      loading,

      // CampaignInbound Selection
      selectedCampaignInbounds,
      toggleSelectedCampaignInbound,
      selectAllCampaignInboundCheckbox,
      isSelectAllCampaignInboundCheckboxIndeterminate,
      selectAllCheckboxUpdate,
      isAllSelectedCampaignInboundAreRead,

      // CampaignInbound Actions
      toggleStarred,
      updateSelectedCampaignInboundLabelMultiple,
      markSelectedCampaignInboundAsUnreadMultiple,
      markSelectedCampaignInboundAsReadMultiple,
      setSelectedCampaignInboundActionMultiple,
      deleteSelectedCampaignInboundMultiple,

      // CampaignInbound View
      isCampaignInboundViewOpen,
      campaignInboundView,
      openCampaignInboundMeta,
      updatecampaignInboundView,
      updateOpenCampaignInboundLabel,
      markOpenCampaignInboundAsUnread,
      changeOpenCampaignInbound,
      setOpenCampaignInboundAction,

      // Campaign Inbound Ask Accept
      isCampaignInboundAcceptDialogVisible,
      campaignInboundAcceptDialog,
      askCampaignInboundAcceptDialog,
      askCampaignInboundAcceptDialogResponse,

      // useCampaignInbound
      resolveCampaignInboundLabelColor,
      resolveCampaignInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CAMPAIGN_INBOUND_STATUS,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Filter/Formatter
      formatDateToMonthShort,

      // Icons
      icons: {
        // Menu,
        mdiTune,
        mdiMagnify,

        // Actions
        mdiTrashCanOutline,
        mdiReload,

        // Starred,
        mdiStarOutline,
        mdiStar,

        // Read / Unread
        mdiEmailOutline,
        mdiEmailOpenOutline,
      },
    }
  },
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  @import '~@core/preset/preset/mixins';

  .app-campaign-inbound {
    // Style search input
    .campaign-inbound-search-input {
      .v-input__slot {
        fieldset {
          border: none !important;
        }
      }
    }
  }

  // No se usa (Ver Campaign Style)
  @include theme(campaign-inbound-search-input) using ($material) {
    .v-input__slot {
      input {
        caret-color: map-deep-get($material, 'text', 'primary') !important;
      }
    }
  }

  .ps-campaign-inbounds-list {
    // height: calc(100% - 96px - 2px); // 96+2 deben ser el footer que no lleva
    height: calc(100%);

    .campaign-inbound-item {
      &.hovered {
        transform: translateY(-2px);
        transition: all 0.2s;
        z-index: 1;
      }
    }
  }

  @include theme--child(ps-campaign-inbounds-list) using ($material) {
    .campaign-inbound-item {
      &:not(:last-child) {
        border-bottom: 1px solid map-deep-get($material, 'dividers');
      }
      &.campaign-inbound-read {
        background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
      }
    }
  }
</style>
